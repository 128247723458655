import "../Stylesheets/Resume.css";
import pdf from "../images/ResumePDF.jpg";
import React from "react";
import { Link } from "react-router-dom";

function Resume() {
  return (
    <div className="Resume">
      <a
        href="https://github.com/mamugu01"
        target="_blank"
        rel="noopener noreferrer"
        className="Github"
      >
        https://github.com/mamugu01
      </a>
      <a
        href="https://www.linkedin.com/in/matthew-amuguni/"
        target="_blank"
        rel="noopener noreferrer"
        className="LinkedIN"
      >
        www.linkedin.com/in/matthew-amuguni/
      </a>

      <div className="TopBar">
        <Link className="leftTopButton" to="/">
          Home
        </Link>

        <Link className="regularTopButton" to="/spotify">
          Spotify
        </Link>
      </div>
      <p className="HeaderText">Resume</p>
      <div className="App-header">
        <div className="imageStack">
          {" "}
          <img src={pdf} alt="react logo" className="Resumejpg" />
        </div>
      </div>
    </div>
  );
}

export default Resume;
