import "../Stylesheets/Spotify.css";
import React from "react";
import { Link } from "react-router-dom";
import { Spotify } from "react-spotify-embed";

function Spot() {
	return (
		<div className="Spotify">
			<div className="TopBar">
				<Link className="leftTopButton" to="/">
					Home
				</Link>

				<Link className="regularTopButton" to="/resume">
					Resume
				</Link>
			</div>
			<p className="HeaderText">Spotify</p>
			<div className="App-header">
				<div className="AboutBox">
					<p className="introText">
						{" "}
						I have developed a deep appreciation for music (as do
						many), and starting from my Junior year in college, I
						embarked on a personal endeavor to curate monthly
						playlists encompassing the songs that stood out to me.
						These playlists represent a collection of cherished
						tracks I have discovered and enjoyed throughout the
						months. Below, you will find an overview of all the
						playlists I have meticulously crafted thus far:
					</p>
				</div>
				<p className="year2024">2024</p>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle4">March 2024</p>
						<Spotify link="https://open.spotify.com/playlist/5pnafdU3fLjWd0ciRgDalE?si=2e8d8b13a5144bf9" />
					</div>
					<div className="imageStack">
						<p className="PTitle4">February 2024</p>
						<Spotify link="https://open.spotify.com/playlist/3cVblRiGWHvnYeuQXNpHDE?si=120c40cc644a4929" />
					</div>
					<div className="imageStack">
						<p className="PTitle4">January 2024</p>
						<Spotify link="https://open.spotify.com/playlist/3wxsybah3e0YKpt5xkOQlL?si=9f53d35133d94d61" />
					</div>
				</div>
				<p className="year2023">2023</p>

				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle">December 2023</p>
						<Spotify link="https://open.spotify.com/playlist/2dIEjNlxAeFnHp8RlulSVF?si=eefd713467d34c0d" />
					</div>
					<div className="imageStack">
						<p className="PTitle">November 2023</p>
						<Spotify link="https://open.spotify.com/playlist/3B0lOxFnwwiOBj4fzGWgX4?si=8ecbc40d3a634b7e" />
					</div>
					<div className="imageStack">
						<p className="PTitle">October 2023</p>
						<Spotify link="https://open.spotify.com/playlist/7jujFmgHnI7HXh1qoZY9Mr?si=2d43eb0f1ec14207" />
					</div>
				</div>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle">September 2023</p>
						<Spotify link="https://open.spotify.com/playlist/0M6tlJHsgphh1y8VXU2hGx?si=b8e871ee2e954386" />
					</div>
					<div className="imageStack">
						<p className="PTitle">August 2023</p>
						<Spotify link="https://open.spotify.com/playlist/0VokbjrjG1IIk7qRNy9Kvc?si=d47780e9e6d64fef" />
					</div>
					<div className="imageStack">
						<p className="PTitle">July 2023</p>
						<Spotify link="https://open.spotify.com/playlist/0tc1GEgRHyVcUAagA1iIu9?si=c0bc58e4595d45d8" />
					</div>
				</div>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle">June 2023</p>
						<Spotify link="https://open.spotify.com/playlist/0o2UcmVt9Ng7QPGITwztwb?si=41fb9da45049482b" />
					</div>
					<div className="imageStack">
						<p className="PTitle">May 2023</p>
						<Spotify link="https://open.spotify.com/playlist/3q3IRm8qDrXWwDJuDLpIfc?si=bc87af0a7e3e4fd9" />
					</div>
					<div className="imageStack">
						<p className="PTitle">April 2023</p>
						<Spotify link="https://open.spotify.com/playlist/6pDMxwqD6w84Ps5uj0Bm7n?si=c91d1e6735904695" />
					</div>
				</div>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle">March 2023</p>
						<Spotify link=" https://open.spotify.com/playlist/4em5h4CtabmURBiBpvhlwP?si=b607bd3293604afc" />
					</div>
					<div className="imageStack">
						<p className="PTitle">February 2023</p>
						<Spotify link="https://open.spotify.com/playlist/2ZXOky4FAOMBkP07vsVPEL?si=d061ec023dea4c37" />
					</div>
					<div className="imageStack">
						<p className="PTitle">January 2023</p>
						<Spotify link="https://open.spotify.com/playlist/52D6fHECfqRWiOiKoPIK2i?si=340fe656ca9b4ae7" />
					</div>
				</div>
				<p className="year2022">2022</p>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle2">December 2022</p>
						<Spotify link="https://open.spotify.com/playlist/2clOiH9K4QuH6rXtdi3VO9?si=d6e02a4b79124e84" />
					</div>
					<div className="imageStack">
						<p className="PTitle2">November 2022</p>
						<Spotify link="https://open.spotify.com/playlist/7ypVJn2Qo7TWzcNULfpkBo?si=1e2743ef44de4b5d" />
					</div>
					<div className="imageStack">
						<p className="PTitle2">October 2022</p>
						<Spotify link="https://open.spotify.com/playlist/4oD1YGYfbqgR5AIjHrGG7b?si=fc3ccbe7d3c343c4" />
					</div>
				</div>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle2">September 2022</p>
						<Spotify link="https://open.spotify.com/playlist/6uxLrtte3IQWMZz3njxRnQ?si=cbb524cccea0490a" />
					</div>
					<div className="imageStack">
						<p className="PTitle2">August 2022</p>
						<Spotify link="https://open.spotify.com/playlist/3LM3ppCELTPHRMJHJnXsDE?si=5c84d0e1722041d3" />
					</div>
					<div className="imageStack">
						<p className="PTitle2">July 2022</p>
						<Spotify link="https://open.spotify.com/playlist/4RtbrvIfoy17GgheMRxdwn?si=87bb22d773c74190" />
					</div>
				</div>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle2">June 2022</p>
						<Spotify link="https://open.spotify.com/playlist/6uxLrtte3IQWMZz3njxRnQ?si=cbb524cccea0490a" />
					</div>
					<div className="imageStack">
						<p className="PTitle2">April/May 2022</p>
						<Spotify link="https://open.spotify.com/playlist/7C3xCLyrdZ7wceYqeoaZuA?si=f5ebda24754342e7" />
					</div>
					<div className="imageStack">
						<p className="PTitle2">March 2022</p>
						<Spotify link="https://open.spotify.com/playlist/26R3LLslHwAEGJzT16Pvz4?si=f543800037884c94" />
					</div>
				</div>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle2">February 2022</p>
						<Spotify link="https://open.spotify.com/playlist/3f2DrWwL9vtpuM9inH6qme?si=0bc2351ada984070" />
					</div>
					<div className="imageStack">
						<p className="PTitle2">January 2022</p>
						<Spotify link="https://open.spotify.com/playlist/6X75k53EWjwMLDadPP1JCV?si=6bf343e651c943c4" />
					</div>
				</div>
				<p className="year2021"></p>
				<div className="imageGallery">
					<div className="imageStack">
						<p className="PTitle3">December 2021</p>
						<Spotify link="https://open.spotify.com/playlist/4Iv8wP4qHMTLRYlmzf7f96?si=94aeae9ec3784a62" />
					</div>
					<div className="imageStack">
						<p className="PTitle3">November 2021</p>
						<Spotify link="https://open.spotify.com/playlist/64GUQUXjUkXiePQCBNwTGV?si=420ef2ca817748fb" />
					</div>
					<div className="imageStack">
						<p className="PTitle3">October 2021</p>
						<Spotify link="https://open.spotify.com/playlist/0UKqsaLepP9qMo3PPQ6ogS?si=519a69a4d3224e83" />
					</div>
				</div>
				<div className="imageStack">
					<p className="PTitle3">September 2021</p>
					<Spotify link="https://open.spotify.com/playlist/0q975SK7T8SMog9tp6OqQX?si=3d58009718304823" />
				</div>
			</div>
		</div>
	);
}

export default Spot;
