import "../Stylesheets/Home.css";
import React from "react";
import grad from "../images/Grad.jpg";
import Kenya from "../images/Kenya1.jpg";
import MomDad from "../images/MomDad.jpg";
import coconut from "../images/CoconutEnjoyer.jpg";
import Mementos from "../images/Mementos.jpg";
import birthday from "../images/21Birthday.jpg";
import { Link } from "react-router-dom";

function Home() {
	return (
		<div className="App">
			<div className="TopBar">
				<Link className="leftTopButton" to="/resume">
					Resume
				</Link>

				<Link className="regularTopButton" to="/spotify">
					Spotify
				</Link>
				<a
					className="regularTopButton"
					href="https://github.com/mamugu01"
					target="_blank"
					rel="noopener noreferrer"
				>
					GitHub
				</a>
			</div>
			<header className="App-header">
				<p className="HeaderText">Matt's World</p>
				<div className="imageGallery">
					<img src={grad} alt="react logo" className="grad" />
					<img src={Kenya} alt="react logo" className="grad" />
				</div>
				<div className="imageGallery">
					<img src={MomDad} alt="react logo" className="grad" />
					<img src={coconut} alt="react logo" className="grad" />
				</div>
				<div className="imageGallery">
					<img src={birthday} alt="react logo" className="grad" />
					<img src={Mementos} alt="react logo" className="grad" />
				</div>
				<p className="AboutTag"> About Me</p>

				<div className="AboutBox">
					<div className="ImageStack">
						<p className="introText">
							{" "}
							Greetings and thank you for visiting my page. My
							name is Matthew Amuguni, a recent graduate of Tufts
							University, where I successfully completed my
							studies in Computer Science and Applied Mathematics.
							At present, I am actively seeking opportunities in
							the fields of Data Science, Data Analytics, Data
							Engineering, or Software Engineering. Originating
							from Worcester, Massachusetts, I come from a Kenyan
							heritage, with my family having relocated to the
							United States.
						</p>
						<p className="introText">
							Alongside my professional pursuits, I hold a deep
							passion for basketball, gaming, and have an
							insatiable enthusiasm for discussing the intricacies
							of life.
						</p>
						<p className="introText">
							Feel free to explore my page further, and I look
							forward to engaging with you on these fascinating
							subjects and potential career opportunities.
						</p>
					</div>
				</div>
			</header>
		</div>
	);
}

export default Home;
