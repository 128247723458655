import React from "react";

import Resume from "./component/Resume.jsx";
import Home from "./component/home.jsx";
import Spot from "./component/spotify.jsx";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/spotify" element={<Spot />} />
    </Routes>
  );
}

export default App;
